import { Injectable } from '@angular/core';
import {API_TYPE, HttpHelperService} from '../../../services/http-helper.service';
import { PHONE_CODE_DEFAULT_COUNTRYID } from './shared.service';
import { Modal, overlayConfigFactory } from 'ngx-modialog-7';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { Router } from '@angular/router';
import {DatePipe} from "@angular/common";

export const SESSION_KEY_AUTH_USER = 'APP_AUTH_USER';
export const SESSION_KEY_AUTH_ROLES = 'APP_AUTH_ROLES';
export const SESSION_KEY_AUTH_ACCOUNT = 'APP_AUTH_ACCOUNT';
export const SESSION_KEY_AUTH_MEMBERSHIPS = 'APP_AUTH_MEMBERSHIPS';
export const SESSION_KEY_AUTH_OWNERSHIPS = 'APP_AUTH_OWNERSHIPS';

export const SESSION_KEY_LOADING_PROFILE = 'LOADING_PROFILE';

export const ADMIN_ROLES = {
  SYSTEM_ADMIN: 'System Admin',
  BUSINESS_MANAGER_ADMIN: 'Business Manager Admin',
  BUSINESS_MANAGER_ADMIN_BETA: 'Business Manager Admin BETA',
};

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private httpHelper: HttpHelperService,
    public modal: Modal,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  // -- function to display confirmation dialog
  showConfirmation(
    paramObj: any,
    proceedCallBackFunc: any,
    cancelCallBackFunc?: any
  ) {
    const dialog = this.modal.open(
      ConfirmationPopupComponent,
      overlayConfigFactory(paramObj, BSModalContext)
    );
    dialog.result.then((data) => {
      if (data) {
        proceedCallBackFunc.call();
      } else if (cancelCallBackFunc) {
        cancelCallBackFunc.call();
      }
    });
  }

  setAuthMemberships(data: any) {
    if (Array.isArray(data) && data.length) {
      sessionStorage.setItem(
        SESSION_KEY_AUTH_MEMBERSHIPS,
        JSON.stringify(data)
      );
    }
  }

  getAuthMemberships() {
    let parsedAuthData: any;
    const authData = sessionStorage.getItem(SESSION_KEY_AUTH_MEMBERSHIPS);
    if (authData) {
      parsedAuthData = JSON.parse(authData);
    }
    return parsedAuthData;
  }



  // -------------
  setAuthOwnerships(data: any) {
    if (Array.isArray(data) && data.length) {
      data.forEach(item => {
        const addressData = item.propertyUnitDetails.address;
        item['title'] = this.getFormattedAddress(addressData);
        item['ref_person_number'] =  this.getAuthNIN();
        item['ownership_start_date'] = item['startDate']? this.datePipe.transform(item['startDate'], 'yyyy-MM-dd') : '';

      })

      sessionStorage.setItem(SESSION_KEY_AUTH_OWNERSHIPS, JSON.stringify(data));
    }
  }

  getAuthOwnerships() {
    let parsedAuthData: any;
    const authData = sessionStorage.getItem(SESSION_KEY_AUTH_OWNERSHIPS);
    if (authData) {
      parsedAuthData = JSON.parse(authData);
    }
    return parsedAuthData;
  }

  getAuthOwnership(ownershipId: any) {
    const sessOwnerships: any = this.getAuthOwnerships();
    let matchOwnership: any;
    if(Array.isArray(sessOwnerships)){
      sessOwnerships.forEach((item: any) => {
        if(item.ownershipId && item.ownershipId == ownershipId){
          matchOwnership = item;
        }
      })
    }
    return matchOwnership;
  }
  setAuthUser(data: any) {
    if (data) {
      sessionStorage.setItem(SESSION_KEY_AUTH_USER, JSON.stringify(data));
    }
  }

  setAuthRoles(data: any) {
    if (data) {
      sessionStorage.setItem(SESSION_KEY_AUTH_ROLES, JSON.stringify(data));
    }
  }

  getAuthRoles() {
    let parsedAuthData: any;
    const sessData = sessionStorage.getItem(SESSION_KEY_AUTH_ROLES);
    if (sessData) {
      parsedAuthData = JSON.parse(sessData);
    }
    return parsedAuthData;
  }

  isAuthUserHasAdminRole() {
    const authRoles = this.getAuthRoles();
    let hasAdminRole = false;
    const allowedRoles = [
      ADMIN_ROLES.SYSTEM_ADMIN,
      ADMIN_ROLES.BUSINESS_MANAGER_ADMIN,
      ADMIN_ROLES.BUSINESS_MANAGER_ADMIN_BETA,
    ];
    if (Array.isArray(authRoles)) {
      authRoles.forEach((item) => {
        if (allowedRoles.includes(item.roleName)) {
          hasAdminRole = true;
        }
      });
    }
    // hasAdminRole = false;
    return hasAdminRole;
  }

  setAuthAccount(data: any) {
    if (data) {
      sessionStorage.setItem(SESSION_KEY_AUTH_ACCOUNT, JSON.stringify(data));
    }
  }

  getAuthAccount() {
    let parsedAuthData: any;
    const authData = sessionStorage.getItem(SESSION_KEY_AUTH_ACCOUNT);
    if (authData) {
      parsedAuthData = JSON.parse(authData);
    }
    return parsedAuthData;
  }

  getAuthAzureId() {
    const authAc: any = this.getAuthAccount();
    if (authAc.localAccountId) {
      return authAc.localAccountId;
    }
    return null;
  }

  getAuthSessionId() {
    const authAc: any = this.getAuthAccount();
    if (authAc.homeAccountId) {
      return authAc.homeAccountId;
    }
    return null;
  }

  getAuthUser() {
    let parsedAuthData: any;
    const authData = sessionStorage.getItem(SESSION_KEY_AUTH_USER);
    if (authData) {
      parsedAuthData = JSON.parse(authData);
    }
    return parsedAuthData;
  }

  getAuthAttr(attr: any) {
    const userDetails = this.getAuthUser();
    if (userDetails && userDetails[attr]) {
      return userDetails[attr];
    }
    return null;
  }

  getAuthUserId() {
    return this.getAuthAttr('personId');
  }

  isConsentSubmitted() {
    return this.getAuthAttr('consentSubmitted') ? true : false;
  }
  isConsentGiven() {
    return this.getAuthAttr('consentValue') ? true : false;
  }

  getAuthNIN() {
    const verifiedData: any = this.getAuthAttr('nationalIdentity');
    if (verifiedData && verifiedData['nin']) {
      return verifiedData['nin'];
    }
    return '';
  }

  isPersonVerified() {
    return this.getAuthNIN() ? true : false;
  }

  getAuthEmail() {
    return this.getAuthAttr('email');
  }
  getAuthName() {
    return this.getAuthAttr('name');
  }
  getAuthPhone() {
    return this.getAuthAttr('phone');
  }

  getAuthPhoneCountryId() {
    return this.getAuthAttr('countryId')
      ? this.getAuthAttr('countryId')
      : PHONE_CODE_DEFAULT_COUNTRYID;
  }

  isAuthEmailContainsSpecialDomain(): boolean {
    return this.isAuthUserHasAdminRole();
    /* let domainMatched = false;
    let email: any = this.getAuthEmail();
    if(email){
      email = email.toLowerCase();
      SPECIAL_DOMAINS.forEach((domain: any) => {
        if(email.includes(domain)){
          domainMatched = true;
        }
      });
      if(SPECIAL_ADMIN_EMAILS.includes(email)) {
        domainMatched = true;
      }
    }

    return domainMatched;*/
  }

  isAuthEmailVerified() {
    return true; // this.getAuthAttr('isEmailVerified');
  }

  isAuthPhoneVerified() {
    return this.getAuthAttr('isPhoneVerified');
  }

  getUserInfo() {
    return this.httpHelper.get('LoggedInUserDetails/getUserInfo', {});
  }
  generateVerificationOTP(formData: any) {
    return this.httpHelper.post('Persons/generateVerificationOTP', formData);
  }

  verifyOTP(formData: any) {
    return this.httpHelper.post('Persons/verifyOTP', formData);
  }

  updateAccountInfo(formData: any) {
    return this.httpHelper.post('Persons/updateAccountInfo', formData);
  }

  updateAccountConsent(formData: any) {
    return this.httpHelper.post('Persons/updateAccountConsent', formData);
  }

  getOwnershipDetailsForNabonett(params: any) {
    return this.httpHelper.get(
      'companyprocesses/getOwnershipDetailsForNabonett',
      params
    );
  }

  getOverviewDetailsForNabonett(params: any) {
    return this.httpHelper.get(
      'companyprocesses/getOverviewDetailsForNabonett',
      params
    );
  }

  getMembershipCustomerInvoicesForNabonett(params: any) {
    return this.httpHelper.get(
      'companyprocesses/getMembershipCustomerInvoicesForNabonett',
      params
    );
  }

  downloadInvoice(CompanyId: any, InvoiceId: any) {
    return this.httpHelper.get('CommonInvoicing/getInvoicePdfWrapper', {
      CompanyId,
      InvoiceId,
    });
  }
  getFormattedAddress(srcObj: any, objType?: string): unknown {
    let addressStr = '';
    // -------------------
    const displayCountry: any = false;
    // -------------------
    const address1Keys = ['address1', 'addressLine1', 'Address1', 'AddressLine1'];
    const address2Keys = ['address2', 'addressLine2', 'Address2', 'AddressLine2'];
    const addressNoKeys = ['addressNumber', 'AddressNumber'];
    const zipCodeKeys = ['zipcode', 'ZipCode', 'zip', 'Zipcode', 'zipCode'];
    const cityKeys = ['city', 'City'];
    const addressLetterKeys = ['addressLetter', 'AddressLetter'];
    const countryKeys = ['country', 'Country'];
    const obj: any = {};

    obj.addressLine1 = this.getMatchingValueForKeys(srcObj, address1Keys);
    obj.addressLine2 = this.getMatchingValueForKeys(srcObj, address2Keys);
    obj.addressNumber = this.getMatchingValueForKeys(srcObj, addressNoKeys);
    obj.zipcode = this.getMatchingValueForKeys(srcObj, zipCodeKeys);
    obj.city = this.getMatchingValueForKeys(srcObj, cityKeys);
    obj.country = this.getMatchingValueForKeys(srcObj, countryKeys);
    obj.addressLetter = this.getMatchingValueForKeys(srcObj, addressLetterKeys);
    // ---------------
    const addressDataArray: any = [];

    if (obj.addressLine1){
      addressStr = obj.addressLine1 + '  ';
    }
    if (obj.addressLine2){
      addressStr += obj.addressLine2 + '  ';
    }
    if (obj.addressNumber){
      addressStr += obj.addressNumber + '  ';
    }
    if (obj.addressLetter){
      addressStr += obj.addressLetter + '  ';
    }

    let hasZipCode = false;
    if (obj.zipcode){
      const zipStr = obj.zipcode;
      addressStr = addressStr.trim();
      addressStr = addressStr + ', ';
      addressStr += zipStr;
      hasZipCode = true;
    }
    if (obj.city){
      addressStr = addressStr.trim();
      const zipCityJoiner = hasZipCode ? ' ' : ', ';
      addressStr = addressStr + zipCityJoiner;
      addressStr += obj.city + '  ';
    }

    if (displayCountry && obj.country){
      const joiner = addressStr ? ' ' : '';
      addressStr += (joiner + obj.country);
    }

    if (addressStr.length > 2){
      addressStr = addressStr.trim();
      addressStr = addressStr.replace(/(^,)|(,$)/g, '');
    }
    return addressStr;
  }

  getMatchingValueForKeys(sourceObj: any, keys: any){
    let val = '';
    if (Array.isArray(keys)){
      keys.forEach((key: any) => {
        if (sourceObj[key]){
          val = sourceObj[key];
        }
      });
    }
    return val;
  }


  getShortAddressTitle(obj: any, objType?: string): unknown {
    let addressStr = '';
    // ---------------
    const addressDataArray: any = [];
    if (obj.AddressLine1) {
      addressStr = obj.AddressLine1 + '  ';
    }
    if (obj.AddressLine2) {
      addressStr += obj.AddressLine2 + '  ';
    }
    if (obj.AdressNumber) {
      addressStr += obj.AdressNumber + '  ';
    }

    if (addressStr.length > 2) {
      addressStr = addressStr.trim();
      addressStr = addressStr.replace(/(^,)|(,$)/g, '');
    }
    return addressStr;
  }

  updateLastLogin(AzureID: any, Email: any) {
    const PlatformType = 'NABONETT';
    return this.httpHelper.post('UpdateLastLogin/updateLoginDateTime', {
      AzureID,
      Email,
      PlatformType,
    });
  }

  public getNabonettDocuments(
    personNumber: any,
    customerId: any,
    companyid: any
  ) {
    const additionalHeaders: any = [{ key: 'companyid', value: companyid }];
    const params: any = { personNumber, customerId };
    return this.httpHelper.getWithExtraHeaders(
      'CompanyDocument/getNabonettDocuments',
      additionalHeaders,
      params
    );
  }

  public downloadDocument(
    personNumber: any,
    customerId: any,
    companyid: any,
    documentId: any,
    source: any
  ) {
    const additionalHeaders: any = [{ key: 'companyid', value: companyid }];
    const params: any = { personNumber, customerId, documentId, source };
    return this.httpHelper.getBufferWithExtraHeaders(
      'CompanyDocument/downloadNabonettDocument',
      additionalHeaders,
      params
    );
  }

  AddContactPerson(formData: any) {
    return this.httpHelper.post('Nabonett/AddContactPerson', formData);
  }

  getTpoDetailByPersonNo(personNo: any) {
    return this.httpHelper.get('Nabonett/GetTpoDetailByPersonNo', { personNo });
  }
  downloadTpoFileForNabonett(params: any) {
    return this.httpHelper.get('Nabonett/DownloadTpoFileForNabonett', params);
  }

  redirectToDefaultPage() {
    if (this.getAuthOwnerships()) {
      this.router.navigate(['/account/ownerships']);
    } else if (this.getAuthMemberships()) {
      this.router.navigate(['/account/memberships']);
    } else {
      this.router.navigate(['/account/profile']);
    }
  }

  setProfileLoading(isLoading: any) {
    sessionStorage.setItem(
      SESSION_KEY_LOADING_PROFILE,
      isLoading ? 'TRUE' : 'FALSE'
    );
  }

  isProfileLoading() {
    const val = sessionStorage.getItem(SESSION_KEY_LOADING_PROFILE);
    return val == 'TRUE' ? true : false;
  }

  storeLog(type: any, message: any, data?: any){
    const dataJsonStr = data? JSON.stringify(data): '';
    const authAzureId = this.getAuthAzureId();
    const authEmail = this.getAuthEmail();
    const authPersonId = this.getAuthUserId();
    const authSessionId = this.getAuthSessionId();
    console.log('storeLogData', {authAzureId, authEmail, authPersonId, type, message, dataJsonStr});
    const formData: any = {
      source: 'NABONETT',
      type: type,
      email: authEmail,
      message: message,
      sessionId: authSessionId,
      data: dataJsonStr
    };
    this.httpHelper.post('uiinteraction/savelog', formData, API_TYPE.SIGNVERIFY_API).subscribe(response => {});
  }
}

export const LOG_TYPES = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
}

export const NABONETT_MAIN_CLASS = 'nabonett-site';

export const NO_TXT = {
  COMMON: {
    BACK: 'Tilbake',
    SEARCH: 'Søk',
    NO_RECORDS_FOUND: 'Ingen oppføring funnet',
    LOADING_DATA: 'Laster inn data',
    REQ_ERROR_CONTACT_ADMIN: 'Det har oppstått en feil under forsøk på å behandle forespørselen din. Vennligst kontakt admin.'
  },
  MEMBERSHIP: {
    NO_RECORDS_FOUND: 'Ingen ubetalte kontingenter',
  },
  MEMBONUS: {
    BONUS_AVAILABLE: 'Tilgjengelig Bonus',
    AMT_TO_PAYOUT: 'Beløp til utbetaling',
    AC_FOR_PAYOUT: 'Kontonummer for utbetaling',
    FOR_EARNING: 'Kort lagt inn for opptjening',
    ORDER_PAYOUT: 'Bestill utbetaling',
    CHANGE_AC_NO: 'Endre kontonummer',
    DELETE_AC_NO: 'Slett kontonummer',
    IN_CASE_OF_PAYMENT_TXT:
      'Ved utbetaling vil det ta opp til flere arbeidsdager før pengene står på konto',
    YOU_HAVE_NOT_ADDED_TXT:
      'Du har ikke lagt inn noen kort eller konti for opptjening av bonus',
    DEBIT_CARD_TXT:
      'Har du bankkort med BankAxept-logo, må du registrere ditt kontonummer som du finner på baksiden av kortet. Du kan bare legge til kort som står i ditt navn!',
    CREDIT_CARD_TXT: 'Du kan bare legge til kort som står i ditt navn!',
    NEW_ACC_SAME_EXISTING_ACC:
      'nytt kontonummer er det samme som eksisterende kontonummer',
    ACC_UPDATED_SUCCESSFULLY: 'Kontonummeret har blitt oppdatert',
    INVALID_ACCOUNTNO: 'Ugyldig kontonummer',
    NOT_RESPONDING_ERROR:
      'Vi beklager! Medlemsprogrammet svarer ikke for øyeblikket og vi kan derfor ikke vise deg dine fordeler og bonus. Prøv igjen senere så bør dette være tilgjengelig igjen.',
  },
  ALERT_TITLE: {
    SUCCESS: 'Fullført!',
    FAILED: 'Feil',
    ERROR: 'Error',
  },
  OTP_VERIFIED_SUCCESSFULLY: 'Engangskoden er godtatt.',
  PROFILE: {
    UNVERIFIED_EMAIL_TO_VERIFY: 'Din epost er ikke bekreftet.',
    CLICK_HERE_TO_VERIFY: 'Klikk her for å bekrefte',
    UNVERIFIED_PHONENO_TO_VERIFY: 'Ditt mobilnummer er ikke bekreftet.',
    FAILED_SENDING_EMAIL_OTP:
      'Feil oppstod når vi forsøkte å sende deg kode på epost',
    EMAIL_VERIFICATION_OTP_SENT: 'Engangskode sendt på epost',
    FAILED_SENDING_CONTACTNO_OTP:
      'Feil oppstod når vi forsøkte å sende deg kode på telefon',
    CONTACTNO_VERIFICATION_OTP_SENT: 'Engangskode sendt på telefon',
    NUMBER_ALREADY_IN_USED: 'Nummer som allerede er brukt av en annen person.',
    NUMBER_UPDATED_SUCCESSFULLY: 'Nummeret er oppdatert.',

    INVALID_OTP_MSG: 'Ugyldig engangskode',
    DIDNT_GET_OTP_RETRY_SENDING_MSG:
      'Engangskode sendt nylig. Du kan sende på nytt om:',
    RESEND_OTP_BTN: 'Send engangskode på nytt',
    OTP_EXPIRED_MSG: 'Engangskoden har utløpt, vennligst forsøk igjen',
    ERROR_OCCURED_MSG:
      'Vi beklager - en feil har skjedd. Vennligst forsøk igjen. ',
    OTP_VERIFIED_SUCCESSFULLY: 'Engangskoden er godtatt.',

    USER_IDENTIFICATION_PENDING: 'Brukeridentifikasjon venter',
    USER_IDENTIFIED_SUCCESSFULLY: 'Bruker identifisert',
    EMAIL_IS_VERIFIED: 'E-post er bekreftet.',

    EMAIL_VERIFICATION_PENDING: 'E-postbekreftelse venter.',
    TELEFON_IS_VERIFIED: 'Telefonen er verifisert.',
    TELEFON_VERIFICATION_PENDING: 'Telefonbekreftelse venter.',
  },
  CONSENT_POPUP: {
    CONSENT_UPDATED_MSG: 'Samtykke er lagret',
    ERROR_OCCURED_UPDATE_CONSENT_MSG:
      'En feil oppstod da vi forsøkte å oppdatere ditt samtykke eller avslag',
  },
  OVERVIEW: {
    SYS_ADMIN_SEARCH_BIRTHNO_TXT:
      'Som systemadministrator kan du søke og sjekke enhver person etter fødselsnummer.',
  },
  BANKID: {
    BANKID_VERIFICATION: 'Bekreftelse med BankID',
    VERIFY_YOUR_AC_TXT: 'Bekreft din identitet med BankID',
    BTN_VERIFY_NOW: 'Bekreft nå',
    VERIFICATION_SUCCESS: 'ID bekreftet',
    VERIFICATION_SUCCESS_TEXT: 'Vi har bekreftet din identitet med BankID.',
    PROCEED_TO_PROFILE: 'Du kan nå gå videre til din profil',
    VERIFICATION_ABORTED: 'Bekreftelse avbrutt',
    VERIFICATION_ABORTED_TEXT: 'ID-bekreftelsen ble avbrutt.',
    VERIFICATION_FAILED: 'ID-bekreftelsen feilet',
    SOMETHING_WENT_WRONG: 'Noe gikk galt.',
    VERIFICATION_NOT_COMPLETED: 'ID-bekreftelsen kunne ikke fullføres.',
    PLEASE_TRY_AGAIN:
      'Vennligst forsøkt igjen etter å ha logget inn på din profil.',
  },
  TAX_REPORT: {
    TPO_MSG:
      'Opplysningene under er sendt til Skatteetaten, og må kontrolleres mot temaet "Boliger og eiendeler" i skattemeldingen. Opplysningene kan bli oppdatert helt frem til fristen for innlevering, og må derfor kontrolleres samme dag som skattemeldingen sendes inn.',
    TPO__WITH_CHANGE_MSG:
      'Opplysningene må kontrolleres mot temaet "Boliger og eiendeler" i skattemeldingen. Opplysningene kan bli oppdatert helt frem til fristen for innlevering, og må derfor kontrolleres samme dag som skattemeldingen sendes inn.',
    NO_TPO_MSG:
      'Ingen oppgaver tilgjengelig for deg. I nabonett finner du bare oppgaver som er levert inn fra og med inntektsåret 2023. 2022 er tilsendt pr epost og/eller fysisk post.',
  },
  MEMBERSHIP_BONUS: {
    DELETE_CARD_SUCCESS_MSG: 'Kortet slettet vellykket',
    DELETE_CARD: 'Slett kort',
    AMOUNT_GREATER_0: 'Beløpet skal være større enn 0',
    CASHBACK_ORDERED: 'Cashback bestilt',
    CASHBACK_TITLE: 'Bestill utbetaling',
    CASHBACK_LESS_THAN_BONUS:
      'Ordrebetalingen skal være mindre enn eller lik tilgjengelig bonus',
    BANK_ACC_DELETED: 'Kontonummeret ble slettet',
  },
};

// export const SPECIAL_DOMAINS =  ['@styret.com', '@wingsystech.com', '@bori.no'];
export const SPECIAL_DOMAINS = [
  '@styret.com',
  '@wingsystech.com',
  '@encourgant.com',
  '@bori.no',
];
// export const SPECIAL_ADMIN_EMAILS =  ['jimin.chung@eggsdesign.com'];
export const SPECIAL_ADMIN_EMAILS = ['admin@styret.com'];

import { Injectable } from '@angular/core';
import {API_TYPE, HttpHelperService} from "../../../services/http-helper.service";
import {SESSION_KEY_AUTH_ACCOUNT} from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class OwnershipService {

  constructor( private httpHelper: HttpHelperService,
  ) { }

  getUserOwnerships() {
    return this.httpHelper.get('GetUserOwnerships', {}, API_TYPE.OWNERSHIP_API);
  }

  getOwnershipsForPersonNumber(personNumber:any) {
    return this.httpHelper.get('GetOwnershipsForPersonNumber', {personNumber}, API_TYPE.OWNERSHIP_API);
  }

  setOwnerships(data: any){
    if (data) {
      sessionStorage.setItem(SESSION_KEY_OWNERSHIPS, JSON.stringify(data));
    }
  }

  getOwnershipFromSessionByid(ownershipId: any){
    const sessOwnerships: any = this.getOwnerships();
    let matchOwnership: any;
    if(Array.isArray(sessOwnerships)){
      sessOwnerships.forEach((item: any) => {
        if(item.ownershipId && item.ownershipId == ownershipId){
          matchOwnership = item;
        }
      })
    }
    return matchOwnership;
  }

  getOwnerships() {
    let parsedAuthData: any;
    const sessOwnerships = sessionStorage.getItem(SESSION_KEY_OWNERSHIPS);
    if (sessOwnerships) {
      parsedAuthData = JSON.parse(sessOwnerships);
    }
    return parsedAuthData;
  }

}

export const SESSION_KEY_OWNERSHIPS = 'SESS_KEY_OWNERSHIPS';
